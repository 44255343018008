import React from "react"

import BgImage from "~/components/bg-image"

const PageHero = ({ title, image }) => {
  return (
    <section className="bg-cream">
      <BgImage className="py-8 lg:py-20 mx-auto max-w-screen-3xl" image={image}>
        <div className="container max-w-full mx-auto px-2 sm:px-6 lg:px-8">
          <h1 className="font-title font-semibold text-white text-shadow text-5xl md:text-6xl lg:text-7xl text-center">
            {title}
          </h1>
        </div>
      </BgImage>
    </section>
  )
}

export default PageHero

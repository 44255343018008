import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { SRLWrapper } from "simple-react-lightbox"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import ShopLayout from "~/components/shop-layout"

import useEventPage from "~/hooks/use-event-page"

const EventsPage = () => {
  const { pageHero, pageContent } = useEventPage()

  const options = {
    settings: {},
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
    },
    caption: {
      showCaption: false,
    },
  }

  return (
    <>
      <Seo
        title="Events"
        image={`https:${getSrc(pageContent.coverImage)}`}
        description="I have experience in creating a whole host of different
                  event florals and look forward to hearing your requirements."
      />
      <PageHero title={pageHero.title} image={pageHero.coverImage} />

      <ShopLayout>
        <div className="w-full">
          <GatsbyImage
            image={pageContent.coverImage}
            alt="Other Events · HeartBee Flowers · Winchester"
            className="max-w-xs sm:max-w-xs md:max-w-sm xl:max-w-lg mx-auto mb-10 rounded"
          />

          <div
            className="prose xl:prose-lg mx-auto"
            dangerouslySetInnerHTML={{
              __html: pageContent.bodyText,
            }}
          />
        </div>

        <div className="flex items-center justify-center mt-10">
          <a
            href={pageContent.document.file}
            className="bounce text-center block py-3 px-5 border border-transparent shadow rounded text-white text-md lg:text-lg font-medium bg-orange hover:bg-orange-dark disabled:opacity-50 focus:outline-none"
            download
          >
            {pageContent.document.buttonText}
          </a>
        </div>

        <div className="w-full mt-20">
          <SRLWrapper options={options}>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
              {pageContent.gallery.map(({ id, image, title }) => (
                <GatsbyImage
                  image={image}
                  key={id}
                  alt={title}
                  className="cursor-pointer"
                />
              ))}
            </div>
          </SRLWrapper>
        </div>
      </ShopLayout>
    </>
  )
}

export default EventsPage

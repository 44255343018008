import React, { useState, useEffect } from "react"
import { useMeasure } from "react-use"
import { Link } from "gatsby"
import { IoChevronDown } from "react-icons/io5"
import { useLocation } from "@reach/router"
import { useSpring, animated } from "react-spring"

const NavLink = ({ to, text }) => (
  <Link
    className="transition-all hover:text-orange h-8 font-medium"
    activeClassName="text-orange"
    to={to}
  >
    {text}
  </Link>
)

const ShopMenu = ({ menu }) => {
  const { pathname } = useLocation()
  const defaultHeight = "0px"
  const [isOpen, toggleIsOpen] = useState(false)
  const [menuHeight, setMenuHeight] = useState(defaultHeight)
  const [ref, { height }] = useMeasure()

  const current = menu.find(link => link.to === pathname) || menu[0]

  const openMenu = () => {
    toggleIsOpen(!isOpen)
  }

  const expand = useSpring({
    config: { friction: 10, clamp: true, tension: 210 },
    height: isOpen ? `${menuHeight}px` : defaultHeight,
    paddingTop: isOpen ? "20px" : "0px",
    paddingBottom: isOpen ? "20px" : "0px",
  })

  const spin = useSpring({
    config: { friction: 10, clamp: true, tension: 210 },
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  })

  useEffect(() => {
    setMenuHeight(height + 20)
  }, [height])

  const Links = () => {
    return menu.map(({ to, text }, i) => (
      <NavLink key={`${to}-${i}`} to={to} text={text} />
    ))
  }

  return (
    <>
      <div className="flex flex-col sticky top-16 max-w-prose mx-auto">
        <div className="flex flex-col lg:hidden">
          <button
            className="flex items-center p-4 text-lg border border-orange rounded-md cursor-pointer hover:shadow-md outline-none focus-within:outline-none"
            onClick={openMenu}
          >
            <span className="ml-1 lg:hidden">{current.text}</span>
            <animated.div className="h-5 w-5 ml-auto lg:hidden" style={spin}>
              <IoChevronDown />
            </animated.div>
          </button>

          <animated.div className="overflow-hidden lg:hidden" style={expand}>
            <div ref={ref} className="flex flex-col space-y-2 px-5">
              <Links />
            </div>
          </animated.div>
        </div>

        <div className="hidden lg:block">
          <div className="flex items-center py-4 text-lg border-b border-orange">
            Categories
          </div>

          <div className="hidden lg:flex flex-col space-y-2 mt-8">
            <Links />
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopMenu

import { graphql, useStaticQuery } from "gatsby"

const useEventPage = () => {
  const data = useStaticQuery(graphql`
    {
      page: contentfulPageEnquiryPage(internalName: { eq: "Events Page" }) {
        pageHero {
          title
          coverImage {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        coverImage {
          gatsbyImageData(quality: 70, layout: FULL_WIDTH)
        }
        pageBody {
          childMarkdownRemark {
            html
          }
        }
        document {
          buttonText
          document {
            file {
              url
            }
          }
        }
        gallery {
          images {
            title
            id
            image: gatsbyImageData(
              quality: 80
              width: 1920
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  `)

  return {
    pageHero: {
      title: data.page.pageHero.title,
      coverImage: data.page.pageHero.coverImage.gatsbyImageData,
    },
    pageContent: {
      coverImage: data.page.coverImage.gatsbyImageData,
      bodyText: data.page.pageBody.childMarkdownRemark.html,
      document: {
        buttonText: data.page.document.buttonText,
        file: `https:${data.page.document.document.file.url}`,
      },
      gallery: data.page.gallery.images,
    },
  }
}

export default useEventPage
